<template>
  <div class="content-wrap">
    <div class="show-title">
      <span><strong>打印预览</strong></span>
      <div class="top-button">
        <el-button
          type="primary"
          @click="handleDownload"
          :loading="downLoading"
          v-hasPermi="['coo:trade:download']"
          >下载</el-button
        >
        <el-button
          type="primary"
          @click="handlePrintSheet"
          :loading="printLoading"
          v-hasPermi="['coo:trade:print']"
          >打印</el-button
        >
      </div>
    </div>
    <hr class="hr-line" />
    <div class="content" id="downPdfPage" ref="printPage">
      <div class="content-top">
        <div class="top-title">
          <span>收&nbsp;票&nbsp;单</span>
        </div>
        <div class="content-img">
          <el-image
            style="height: 60px; min-width: 250px"
            v-loading="barCodeLoading"
            :src="barCodeSrc"
          ></el-image>
        </div>
      </div>
      <div class="content-main">
        <div v-loading="tradeLoading">
          <div class="content-result">
            <el-row :gutter="10">
              <el-col :span="8">结算单号：{{ settlement.sheetCode }}</el-col>
              <el-col :span="4">单据日期：{{ settlementTradeDate }}</el-col>
              <el-col :span="8">打印时间：{{ settlementPrintTime }}</el-col>
              <el-col :span="4">匹配状态：{{ settlementMatchStatus }}</el-col>
            </el-row>
          </div>
          <div class="content-matching">
            <div class="matching-items">
              <div>
                <label>购方企业名称：</label>
                <span>{{ settlement.buyerName }}</span>
              </div>
              <div>
                <label>购方银行账号：</label>
                <span>{{ settlement.buyerBankAccount }}</span>
              </div>
              <div>
                <label>供方编码名称：</label>
                <span
                  >{{ settlement.sellerMainCode }}&nbsp;&nbsp;{{
                    settlement.sellerMainName
                  }}</span
                >
              </div>
            </div>
            <div class="matching-items">
              <div>
                <label>购方企业税号：</label>
                <span>{{ settlement.buyerTaxNo }}</span>
              </div>
              <div>
                <label>结算主体名称：</label>
                <span>{{ settlement.buyerMainName }}</span>
              </div>
              <div>
                <label>开票未税金额：</label>
                <span>{{ fmtTradeAmount(settlement.amount) }}</span>
              </div>
            </div>
            <div class="matching-items">
              <div>
                <label>购方地址电话：</label>
                <span>{{ settlement.buyerAddressTel }}</span>
              </div>
              <div>
                <label>供方企业税号：</label>
                <span>{{ settlement.sellerTaxNo }}</span>
              </div>
              <div>
                <label>结算应付总额：</label>
                <span>{{ fmtTradeAmount(settlement.sumAmount) }}</span>
              </div>
            </div>
          </div>
        </div>

        <hr class="hr-line" />
        <div class="content-table">
          <div class="table-title">
            <span><strong>发票清单</strong></span>
          </div>
          <el-table
            :data="invoiceList"
            stripe
            border
            v-loading="invoiceLoading"
            :header-cell-style="handleHeaderCellStyle"
            class="table-data"
          >
            <el-table-column
              type="index"
              label="序号"
              width="80"
              align="center"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="invoiceType"
              label="发票类型"
              min-width="160"
              :formatter="fmtInvoiceType"
            ></el-table-column>
            <el-table-column
              prop="invoiceTime"
              label="开票日期"
              width="120"
              :formatter="fmtInvoiceTime"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.invoiceNo"
              label="发票号码"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.invoiceCode"
              label="发票代码"
              min-width="160"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.eInvoiceNo"
              label="数电票号"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.amount"
              label="未税金额"
              min-width="140"
              align="right"
              header-align="right"
              :formatter="fmtInvoiceAmount"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.taxAmount"
              label="发票税额"
              min-width="140"
              align="right"
              header-align="right"
              :formatter="fmtInvoiceAmount"
            ></el-table-column>
            <el-table-column
              prop="colInvoice.sumAmount"
              label="价税合计"
              min-width="160"
              align="right"
              header-align="right"
              :formatter="fmtInvoiceAmount"
            ></el-table-column>
          </el-table>
        </div>
        <div class="content-bottom">
          <div>
            <label>
              <p align="left" style="text-indent: 2em; line-height: 2em">
                我司承诺以上所提发票，开具内容符合财政部颁布的《发票管理办法》及相关法律法规的规定，
                且与对应的结算申请单据所包含的验收或销售商品一致，若发票价税合计与结算单据价税合计不一致时，
                按较低者回款，以此承担合同约定的法律风险及税务风险。
              </p>
            </label>
          </div>
          <div
            style="
              height: 100px;
              text-align: right;
              margin-top: 50px;
              margin-right: 120px;
              font-size: 16px;
            "
          >
            <span>盖章标识：(公章或财务章)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import printJS from "print-js";
import html2canvas from "html2canvas";
import { fmtMoney, fmtCurrency } from "@/assets/js/format-util";
import {
  reqSelectSettlement,
  reqSettlementBarCode,
} from "@/service/coo/trade.js";
import { reqPagingSettlementRelInvoice } from "@/service/coo/trade-rel-invoice.js";

export default {
  name: "CooTradeEvidenceDetail",
  props: {
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  data() {
    return {
      settlementId: this.$route.query.id,

      barCodeSrc: undefined,
      barCodeLoading: false,

      printLoading: false,
      downLoading: false,

      settlement: {},
      tradeLoading: false,

      invoiceParams: {
        oriTradeId: undefined,
        pageNo: 1,
        pageSize: 100,
      },
      invoiceList: [],
      invoiceTotal: 0,
      invoiceLoading: false,
    };
  },
  created() {
    this.$watch(
      () => this.$route.path,
      () => {
        this.handleGetBarCode();
        this.handleQuerySettlement();
        this.getReceiveInvoicePage();
      }
    );

    this.handleGetBarCode();
    this.handleQuerySettlement();
    this.getReceiveInvoicePage();
  },
  computed: {
    settlementTradeDate() {
      return this.$moment(this.settlement.tradeTime).format("YYYY-MM-DD");
    },
    settlementPrintTime() {
      return this.$moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
    },
    settlementMatchStatus() {
      return "CG" == this.settlement.auditStatus ? "已匹配" : "未匹配";
    },
  },
  methods: {
    // 获取结算订单
    async handleQuerySettlement() {
      this.tradeLoading = true;
      const res = await reqSelectSettlement(this.settlementId);
      if (res.success) {
        this.settlement = res.data;
      }
      this.tradeLoading = false;
    },
    // 查询收票列表
    async getReceiveInvoicePage() {
      this.invoiceParams.oriTradeId = this.settlementId;
      this.invoiceLoading = true;
      const { success, data } = await reqPagingSettlementRelInvoice(
        this.invoiceParams
      );
      if (success) {
        this.invoiceList = data.list;
        this.invoiceTotal = data.total;
      }
      this.invoiceLoading = false;
    },
    // 获取条形码图
    handleGetBarCode() {
      this.barCodeLoading = true;
      reqSettlementBarCode(this.settlementId).then((res) => {
        if (res.success) {
          this.barCodeSrc = res.data;
        }
        this.barCodeLoading = false;
      });
    },
    // 打印单据凭证
    handlePrintSheet() {
      this.printLoading = true;
      setTimeout(() => {
        html2canvas(this.$refs.printPage, {
          backgroundColor: null,
          useCORS: true,
          windowHeight: document.body.scrollHeight,
        }).then((canvas) => {
          const url = canvas.toDataURL();
          this.img = url;
          printJS({
            printable: url,
            type: "image",
            documentTitle: "打印凭证",
          });
        });
        this.printLoading = false;
      }, 100);
    },
    // 下载单据凭证
    handleDownload() {
      this.downLoading = true;
      this.getPdf(
        `${this.settlement.sheetCode}-收票单-${this.$moment(new Date()).format(
          "YYYYMMDDhhmmss"
        )}`
      );
      setTimeout(() => {
        this.downLoading = false;
      }, 1000);
    },
    // 格式发票类型
    fmtInvoiceType(row) {
      return this.handleValueToLabel("InvoiceType", row.colInvoice.invoiceType);
    },
    // 格式开票日期
    fmtInvoiceTime(row) {
      let date = row.colInvoice.invoiceTime;
      if (date == undefined) return "";
      return this.$moment(date).format("YYYY-MM-DD");
    },
    fmtTradeAmount(value) {
      return fmtMoney(value);
    },
    // 格式金额字段
    fmtInvoiceAmount(row, column, value) {
      return fmtCurrency(value, 2, "", "", ".", "");
    },
    // 表格表头样式
    handleHeaderCellStyle() {
      return "background-color:#EEEEEE;color:#333333;font-wight:400;";
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrap {
  background-color: #fff;
  font-size: 14px;
  line-height: 22px;
  font-family: PingFangSC-Medium, PingFang SC;

  .show-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0 24px;
    height: 24px;
    font-size: 16px;

    span:before {
      content: "|";
      display: inline-block;
      margin-right: 10px;
      background-color: #333333;
    }
  }

  .content {
    position: relative;
    margin-bottom: 24px;
    padding: 16px auto 16px auto;

    .content-top {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      position: relative;

      .top-title {
        font-size: 28px;
      }

      .content-img {
        position: absolute;
        right: 40px;
        bottom: 10px;
      }
    }

    .content-main {
      margin: 0px;
      padding: 0px 24px;

      .content-result {
        background: #ebf4ff;
        height: 36px;
        line-height: 36px;
        padding: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular;
        box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
      }

      .content-matching {
        padding: 20px 10px 0px 10px;
        font-size: 14px;
        line-height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .matching-items {
          display: flex;
          flex-direction: column;

          label {
            display: inline-block;
            color: #666666;
          }

          span {
            color: #333333;
          }
        }
      }

      .content-table {
        margin: 10px 0px;

        .table-title {
          padding: 5px 10px;
          text-align: left;
          font-size: 16px;
          font-weight: 500;
        }

        .table-data {
          margin-top: 12px;
        }
      }
    }

    .content-bottom {
      margin: 40px 24px 0 24px;

      label {
        color: #666666;
        font-size: 14px;
      }
    }
  }

  .hr-line {
    margin-top: 16px;
    background-color: #e9e9e9;
    height: 1px;
    border: 0px;
  }

  span {
    color: #333333;
  }

  ::v-deep .el-table .el-table__cell {
    padding: 6px 0px;
  }
}
</style>